/** @jsx jsx */
import { jsx } from "@emotion/core";
import { FaChevronDown } from "react-icons/fa";
import React, { useLayoutEffect, useRef } from "react";
import cmsUtils from "../../utils/cmsUtils";
import utils from "../../../utils";
import { useRouter } from "../../../components";
import { handleElementLink } from "../../../components";
import env from "../../../env";

export function ExpandableHtml(props) {
  const cmsOption = props.cmsOption || {};
  const [open, setOpen] = React.useState(cmsOption.isCmsEdit);
  const ver = env.ver;
  const item = props.item;
  //const subItems = item.items || [];
  const { history } = useRouter();
  //console.log('item', item, cmsOption);
  //console.log(open)
  let content = cmsOption.isCmsEdit
    ? cmsUtils.payload(item, "Content")
    : cmsUtils
        .payload(item, "Content")
        .replace(
          "__version__",
          "<a href = '/app' class = 'app_version'> PWA " + ver + "</a>"
        );

  const buttonTitle = cmsUtils.payload(item, "ButtonTitle");

  const cssClass = utils.classNames(
    "cms_item",
    "htmlPanel exHtml",
    cmsOption.isCmsEdit && "html",
    item.cssClass || item.anchorName || ""
  );

  //let itemElt = (<div dangerouslySetInnerHTML={{ __html: content }}></div>);

  function handleClick(e) {
    let target = "";
    if (e.target.closest("a")) {
      target = e.target.closest("a").getAttribute("target");
    }
    const processed =
      target === "_blank"
        ? handleElementLink(e.target, null)
        : handleElementLink(e.target);
    //const link = utils.url.getLinkFromElement(e.target);
    //console.log('clicked', e, e.target, processed);

    if (processed.handled) {
      e.preventDefault();
      e.stopPropagation();
    } else if (processed.linkInfo && processed.linkInfo.type === "internal") {
      e.preventDefault();
      e.stopPropagation();
      history.push(processed.linkInfo.url);
    } else {
      // e.preventDefault();
      // e.stopPropagation();
      // setPdfViewPath(utils.site.resourcePath(link))
    }
  }

  const htmlBlock = useRef(null);
  // function iframeSizer() {
  //   //console.log('iframesizer')
  //   let iframesContainer = htmlBlock.current.querySelectorAll('iframe.youtube, .ytvideo iframe');
  //   //console.log('iframesizer', iframesContainer)
  //   if (iframesContainer && iframesContainer.length) {
  //     iframesContainer.forEach(element => {
  //       let height = (element.parentElement.offsetWidth * 9) / 16
  //       //console.log(height, element.parentElement.offsetWidth)
  //       element.style.height = height + 'px';
  //     });
  //   }
  // }

  useLayoutEffect(() => {
    const buttons = htmlBlock.current.querySelectorAll(".button");
    for (let i = 0; buttons && i < buttons.length; i++) {
      restructureButton(buttons[i]);
    }

    // const youtubes = htmlBlock.current.querySelectorAll('iframe[src*="youtube"]');
    // console.log(youtubes)

    // iframeSizer();
    // window.addEventListener('resize', iframeSizer)
    // return (): void => {
    //   window.removeEventListener('resize', iframeSizer)
    // }
  }, []);

  const style = {
    icon: {
      marginLeft: "0.5rem",
      width: "auto !important",
      fontSize: "80%",
      transition: "all 0.5s",
    },
    iconOpen: {
      transform: "translateY(-0.1rem) rotate(-180deg)",
    },
    iconClosed: {
      transform: "translateY(-0.1rem) rotate(0)",
    },
    panelCollapsed: {
      maxHeight: 0,
      transition: "all 0.6s",
      opacity: 0,
      overflow: "hidden",
    },
    panelOpen: {
      opacity: 1,
      maxHeight: "2000px",
      transition: "all 1s",
    },
  };

  const toggleExPanel = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setOpen(!open);
  };

  if (cmsOption.isCmsEdit)
    return (
      <div
        ref={htmlBlock}
        className={cssClass}
        data-cms-item-id={cmsOption.isCmsEdit && item.itemId}
        id={item.anchorName}
        onClick={(e) => handleClick(e)}
      >
        <div css={{ textAlign: "center" }}>
          <a
            className='button'
            href='/'
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
            }}
          >
            <strong>{buttonTitle}</strong>{" "}
            <span css={[style.icon, open ? style.iconOpen : style.iconClosed]}>
              <FaChevronDown />
            </span>{" "}
          </a>
          <div css={{ color: "chocolate" }}>
            To edit the button title, please double click or open item editor
          </div>
        </div>

        <div
          data-cms-html='true'
          data-cms-item-id={cmsOption.isCmsEdit && item.itemId}
          css={[
            { margin: "1rem 0" },
            open ? style.panelOpen : style.panelCollapsed,
          ]}
          dangerouslySetInnerHTML={{ __html: content }}
        ></div>
      </div>
    );

  return (
    <div
      ref={htmlBlock}
      className={cssClass}
      data-cms-html='true'
      data-cms-item-id={cmsOption.isCmsEdit && item.itemId}
      id={item.anchorName}
      onClick={(e) => handleClick(e)}
    >
      <div css={{ textAlign: "center" }}>
        <a className='button' href='/' onClick={toggleExPanel}>
          <strong>{buttonTitle}</strong>{" "}
          <span css={[style.icon, open ? style.iconOpen : style.iconClosed]}>
            <FaChevronDown />
          </span>{" "}
        </a>
      </div>

      <div
        css={[
          { margin: "1rem 0" },
          open ? style.panelOpen : style.panelCollapsed,
        ]}
        dangerouslySetInnerHTML={{ __html: content }}
      ></div>
    </div>
  );
}

function restructureButton(el) {
  if (!el.querySelector("span")) {
    const span = document.createElement("span");
    span.innerHTML = el.innerHTML;
    el.innerHTML = "";
    el.appendChild(span);
  }
}
