import React from "react";
import cmsUtils from "../../utils/cmsUtils";
import utils from "../../../utils";

export function ImagePanel(props) {
  const item = props.item;
  const cmsOption = props.cmsOption || {};
  //console.log(cmsOption)
  //console.log('item', item);
  const heightSet = cmsUtils.payload(item, "HeightSet") || "standard";
  const type = cmsUtils.payload(item, "Type") || "normal";

  const cssClass = utils.classNames(
    "cms_item",
    "imagePanel",
    type && type === "type2" ? "parallax__type2" : "parallax",
    `bannerSize--${heightSet}`,
    item.cssClass || item.anchorName || ""
  );

  const bgUrl =
    cmsOption.pageOptions && cmsOption.pageOptions.imagePath
      ? utils.site.resourcePath(cmsOption.pageOptions.imagePath)
      : utils.site.resourcePath(cmsUtils.payload(item, "ImageUrl"));

  const _mobileUrl = cmsUtils.payload(item, "BottomImageUrl") || "";
  const mobileUrl =
    _mobileUrl === "" ? "" : utils.site.resourcePath(_mobileUrl);

  const rightBg = utils.site.isMobile()
    ? mobileUrl === ""
      ? bgUrl
      : mobileUrl
    : bgUrl;
  
    //const rightBg = "/assets/test.jpg";

  const htmlBlock = React.useRef(null);
  const cssClass2 = utils.classNames(
    "cms_item",
    "htmlPanel",
    cmsOption.isCmsEdit && "html",
    item.cssClass || item.anchorName || ""
  );
  const content = cmsUtils.payload(item, "Content");

  return (
    <div
      className={cssClass}
      data-cms-item-id={cmsOption.isCmsEdit && item.itemId}
      id={item.anchorName}
      data-pxh={heightSet}
      style={{ backgroundImage: utils.css.bgUrlStyle(rightBg) }}
    >
      <div
        ref={htmlBlock}
        className={cssClass2}
        data-cms-html="true"
        data-cms-item-id={cmsOption.isCmsEdit && item.itemId}
        id={item.anchorName}
        dangerouslySetInnerHTML={{ __html: content }}
      ></div>
    </div>
  );
}
