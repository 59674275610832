import { css } from '@emotion/core';
import { mq } from '../../cssInJs'

const padding = [20, null, null, 30];
const paddingHalf = padding.map(x => x ? x / 2 : null);

export const topNav = {
  nav: css(mq({
    position: 'absolute',
    right: [44, null, null, 56],
    zIndex: '1000',
    overflow: 'hidden',
    display:'none',
    '&.show' : {
        display:'block'
    }
  })),
  skew: css(mq({
    background: 'rgba(255,255,255,0.15)',
    transform: 'skewX(-12deg) translateX(15px)',
    transformOrigin: 'top left',
    paddingRight: padding,
    paddingLeft: paddingHalf,
  })),
  unskew: css(mq({
    transform: 'skewX(12deg)',
    transformOrigin: 'top left',
    height: [24, 44, null, 56],
    display: 'flex',
    alignItems: 'center',
  })),
  link: css(mq({
    paddingLeft: paddingHalf,
    paddingRight: paddingHalf,
    display: 'inline-block',
    fontSize: [12, 16, 16, 18],
    fontWeight: 'bold',
    textTransform: 'uppercase',
    color: 'white'
  }))
}