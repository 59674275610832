import { css } from '@emotion/core';
import { mq } from '../../cssInJs'

export default {
  instagrams: css({
    marginLeft: '-10px',
    marginRight: '-10px',
    display: 'flex',
    justifyContent: 'flex-start',
    flexWrap: 'wrap',
  }),
  singBanner : css(mq({
    backgroundPosition: ['50% 50%',null, null, null, null, '50% 46%']
  }))
}