import React, { useState, useEffect } from "react";
import { useRouter } from "../../../components";
import { useGet } from "../../../components";
import { Item } from "../../items/item";
import { NotFound } from "../../../components";
import env from "../../../env";
import { useStore } from "../../../store";
import { ErrorPopup, Loading } from "../../../components";
import { useInstagram } from "../../../components";
import { MetaTag } from "../../../components";
import cmsUtils from "../../utils/cmsUtils";
import utils from "../../../utils";
import { gtm } from "../../../lib/tracking";
// import { loadTikTokJS } from "../../../lib/tiktok";

const log = (...args) => {
  //console.log(args);
};
const areEqual = (prevProps, nextProps) => {
  return prevProps.pageData === nextProps.pageData;
};
const PageMemo = React.memo(Page, areEqual);

export function ViewPage() {
  const { dispatch } = useStore();
  const [pageLoaded, setPageLoaded] = useState(false);
  const [data, setData] = useState(null);
  // const [embedTypes, setEmbedTypes] = useState(null);
  //const path = usePath();
  //log('view page', path);
  const { pathname, Redirect } = useRouter();
  useInstagram();

  const get = useGet();
  log("0. viewpage ready");

  useEffect(() => {
    log("*. start loading");
    setPageLoaded(false);
    get.send(env.apiBase + "/api/cms/GetPublishedPage?path=" + pathname);
    // eslint-disable-next-line
  }, [pathname]);

  useEffect(() => {
    if (data) {
      dispatch({ type: "SET_PAGE", payload: data.data });
      dispatch({ type: "SET_MENU", payload: data.menu });
    }
    // eslint-disable-next-line
  }, [data]);

  // useEffect(() => {
  //   if (embedTypes && embedTypes.tiktok) {
  //     loadTikTokJS();
  //   }
  // }, [embedTypes]);

  if (get.loading()) {
    return <Loading />;
  } else if (get.hasErrors()) {
    return <ErrorPopup title="Loading Error" errors={get.errors} />;
  }

  //log(router.pathname);
  //log(get.loading());
  log("1. viewpage component", pageLoaded, get.response);

  const pageData = get.response.data;
  //const menuData = get.response.menu;
  const redirectTo = get.response.redirectTo;

  const cmsOption = {};
  log(pageLoaded, !pageLoaded && !!pageData);
  if (!pageLoaded && pageData) {
    //log(pageLoaded, pageData, menuData)
    log("2. dispatching page & menu");
    setPageLoaded(true);
    setData(get.response);
    // setEmbedTypes(getEmbedTypes(pageData));
    document.title = pageData.pageTitle;
    gtm.pageView(pathname, pageData.pageTitle);
    return null;
  } else if (redirectTo) {
    if (redirectTo.indexOf("http") >= 0 && typeof window !== `undefined`) {
      return (window.location.href = redirectTo);
    } else {
      return <Redirect to={redirectTo} />;
    }
  } else if (!pageLoaded && !pageData) {
    setPageLoaded(true);
    setData(get.response);
    return <NotFound />;
  }

  log("3. call rendering memo");

  const _landingTopNavCms = document.getElementById("landingTopNavCms");
  if (_landingTopNavCms) {
    _landingTopNavCms.classList.remove("show");
  }

  if (pageData) {
    if (_landingTopNavCms && pathname === "/pro-metal") {
      _landingTopNavCms.classList.add("show");
    }
    return <PageMemo pageData={pageData} cmsOption={cmsOption} />;
  } else return <NotFound />;
}

function Page(props) {
  const pageData = props.pageData;
  const cmsOption = props.cmsOption;
  cmsOption.pageData = pageData;
  // document.title = pageData.pageTitle;
  log("4. page rendering", pageData, cmsOption);
  const sectionItems = pageData.sections.map((section, index) => {
    return <Section item={section} key={section.sectionName + index} cmsOption={cmsOption}></Section>;
  });
  return (
    <>
      <MetaTag data={getMetaTag(pageData)} />
      <div className={`cmsPage cmsPage--${pageData.pageTypeCode}`}>{sectionItems}</div>
    </>
  );
}

// function getEmbedTypes(pageData) {
//   const embedTypes = { tiktok: false };
//   const embedDataList = pageData?.embedDataList;
//   embedTypes.tiktok = utils.array.find(embedDataList, (x) => (x.code || "").indexOf("tiktok.com") >= 0);

//   return embedTypes;
// }

function Section(props) {
  log("5. section rendering");
  const item = props.item;
  //const sectionName = item.sectionName;
  const subItems = item.items || [];
  const cmsOption = props.cmsOption || {};
  if (!subItems || subItems.length === 0) return <></>;

  const subItemsElts = subItems.map((subItem, index) => <Item item={subItem} key={subItem.itemId} cmsOption={cmsOption}></Item>);
  return <>{subItemsElts}</>;
}

function getMetaTag(pageData) {
  const country = utils.site.isNZ ? "New Zealand" : "Australia";
  const siteName = env.siteName + " " + country;

  return {
    title: pageData.pageTitle + " | " + siteName,
    description: cmsUtils.payload(pageData, "Description"),
    keywords: cmsUtils.payload(pageData, "Keyword"),
    heroImageUrl: utils.site.fullUrl(cmsUtils.payload(pageData, "ImageUrl")),
    language: "English",
    country: country,
    siteName: siteName,
    url: utils.site.fullUrl(pageData.pageUrl),
    alternates: utils.site.getAlternates(pageData.pageUrl),

    //facebook-domain-verification
    faceBookDomainVerifi: country === "New Zealand" ? env.FaceBookDomainVerifiNZ : env.FaceBookDomainVerifiAU,
    //canonical: utils.site.resourcePath(pageData.pageUrl)
  };
}
