/** @jsx jsx */
import { jsx } from "@emotion/core";
import InnerHTML from "dangerously-set-html-content";
import { InstaEmbedByLink } from "./instagram";
// import { loadScript } from "../../lib/net-loader";
const defaultHolder = typeof window !== `undefined` ? window : {};
const storeName = "__j6_lazy_js";

export function Embed({ code, type, classNames = [] }) {
  const { embedType, embedCode } = getEmbedType({ code, type });
  if (!embedCode) return null;
  classNames.push("embedCodes");
  classNames.push(`embedCodes--${embedType}`);
  if (embedType === "pinterest") loadPinterestJs();
  if (embedType === "instagramlink") return <InstaEmbedByLink className={classNames.join(" ")} url={embedCode} />;
  else return <InnerHTML className={classNames.join(" ")} html={embedCode} />;
}

function loadPinterestJs() {
  if (typeof window !== `undefined`) {
    const recentlyAdded = parseInt(window["jsPinRecent"]) || 0;
    const ts = ~~(new Date().getTime() / 500);
    // console.log(recentlyAdded, ts);
    if (recentlyAdded === ts) return;
    window["jsPinRecent"] = ts;
    // console.log("loading..");
    Object.keys(window)
      .filter((x) => x.indexOf("PIN_") === 0)
      .forEach((x) => delete window[x]);

    loadJs(["//assets.pinterest.com/js/pinit.js"], true, ["pinit.js", "pinit_main.js"]).then((data) => {
      console.log("pinterest loaded", data);
    });
  }
}

function loadJs(scripts, forceToReload, removingScripts) {
  const promises = [];
  scripts.forEach((script) => {
    return promises.push(loadScript(script, window, forceToReload, removingScripts));
  });
  return Promise.all(promises);
}

// var l = function (a, b, c) {
//   var d, e, f;
//   (d = "PIN_" + ~~(new Date().getTime() / 864e5)),
//     a[d]
//       ? (a[d] += 1)
//       : ((a[d] = 1),
//         a.setTimeout(function () {
//           (e = b.getElementsByTagName("SCRIPT")[0]),
//             (f = b.createElement("SCRIPT")),
//             (f.type = "text/javascript"),
//             (f.async = !0),
//             (f.src = c.mainUrl + "?" + Math.random()),
//             e.parentNode.insertBefore(f, e);
//         }, 10));
// };

function loadScript(url, holder_, forceToReload = false, removingScripts) {
  const holder = holder_ || defaultHolder;
  if (!holder[storeName]) holder[storeName] = {};

  return new Promise((resolve, reject) => {
    //resolve if already loaded
    if (!forceToReload && holder[storeName][url] && holder[storeName][url].loaded) {
      resolve({ script: url, loaded: true, networkLoaded: false, status: "Already Loaded" });
    } else {
      // remove previous
      const scripts = document.querySelectorAll("script[src]");
      [...scripts].forEach((x) => {
        // console.log(x.src, url, removingScripts);
        if (
          x.src.replace("https://", "").replace("http://", "").replace("//", "") === url.replace("https://", "").replace("http://", "").replace("//", "") ||
          removingScripts.some((part) => x.src.indexOf(part) >= 0)
        ) {
          console.log(x);
          x.remove();
        }
      });

      // load script
      let script = document.createElement("script");
      script.type = "text/javascript";
      script.src = url;
      script.onload = () => {
        holder[storeName][url] = { loaded: true };
        resolve({ script: url, loaded: true, networkLoaded: true, status: "Loaded" });
      };
      script.onerror = (error) => resolve({ script: url, loaded: false, status: "Load failed" });
      document.getElementsByTagName("head")[0].appendChild(script);
    }
  });
}

export function getEmbedType({ code, type }) {
  const embedType = (type || "auto").toLowerCase();
  const embedCode = (code || "").trim();
  const linkOnly = embedCode.indexOf("http") === 0;
  const hasScript = embedCode.indexOf("<script") >= 0;
  if (embedType !== "instagramlink") {
    if (embedCode.indexOf("instagram.com") > 0 && linkOnly) return { embedType: "instagramlink", embedCode };
    else if (embedCode.indexOf("instagram.com") > 0 && hasScript) return { embedType: "instagram", embedCode };
    else if (embedCode.indexOf("tiktok.com") > 0) return { embedType: "tiktok", embedCode };
    else if (embedCode.indexOf("giphy.com") > 0) return { embedType: "giphy", embedCode };
    else if (embedCode.indexOf("pinterest.com") > 0) return { embedType: "pinterest", embedCode };
  }
  return { embedType, embedCode };
}
