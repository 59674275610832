import React, { useState, useEffect } from "react";
import { useRouter } from "../../components";
//import { navigate, usePath, useLocationChange } from 'raviger';
import { SiteLink } from "../../components";
import { NavBar } from "./navBar";
import { FaAngleLeft } from "react-icons/fa";
import { useHistory } from "../../components";
import utils from "../../utils";
//import env from '../../env';
//import {LandingTopNav} from './landingTopNav';

export function TopHeader(props) {
  const { pathname } = useRouter();
  //const path = usePath();
  const [menuOpened, setMenuOpened] = useState(false);
  const [currentLocation, setCurrentLocation] = useState(pathname);

  //const [scrolled, setScrolled] = useState(false)
  // const history = useHistory();
  // console.log(history.length, history)

  const history = useHistory(pathname);

  const routeChanged = (path, history) => {
    //console.log(path, history);
    //console.log(window.location.hash)
    if (typeof window !== `undefined`) {
      const hash = window.location.hash;
      const search = window.location.search;
      history.push(path + hash + search);

      if (hash) {
        scrollTo();
      }
    }
  };

  useEffect(() => {
    scrollTo();
  }, []);

  //const pageChanged = useCallback(path => routeChanged(path, history), [history])
  // useLocationChange(path => {
  //   routeChanged(path, history);
  //   setCurrentLocation(path);
  // })

  useEffect(() => {
    routeChanged(pathname, history);
    setCurrentLocation(pathname);
    // eslint-disable-next-line
  }, [pathname]);

  function scrollTo() {
    const hash = window.location.hash;
    if (hash) {
      //console.log(hash)
      setTimeout(() => {
        const anchor = utils.ui.findElement(hash);
        if (anchor) utils.ui.scrollTo(anchor, 500);
      }, 500);
    }
  }

  function toggleMenu(e) {
    setMenuOpened(!menuOpened);
  }

  function onCloseMenu() {
    setMenuOpened(false);
  }

  function goBack(e) {
    //e.preventDefault();
    history.getPrevious();
    //console.log(previousLink)
  }

  function backTop() {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }

  // useEffect(() => {
  //   console.log('use history')
  // }, []);

  //const womenSite = env.country === 'au' ? 'https://www.vssassoon.com.au' : 'https://www.vssassoon.co.nz'

  return (
    <>
      <header className='topNav' id='top'>
        {history.history.length > 1 && (
          <SiteLink
            className='topNav__back'
            title='Go Back'
            to={history.getPreviousPath()}
            onClick={goBack}
          >
            <FaAngleLeft /> BACK{" "}
          </SiteLink>
        )}

        {/* {
          history.history.length > 1 && <a className="topNav__back" title="Go Back" href="/" onClick={(e) => goBack(e)}>
          <FaAngleLeft /> BACK
          </a>
        } */}

        {/* <div className="topNav__siteSwitch">
          <span className="topNav__men">MEN</span>
          <span className="topNav__sep">|</span>
          <a className="topNav__women" href={womenSite}>WOMEN</a>
        </div> */}

        <div className='topNav__skewBar'></div>
        <div style={{ height: "100%", position: "relative" }}>
          <SiteLink to='/' className='topNav__logo' onClick={backTop}>
            <img src='/assets/logos/logo.png' alt='logo' />
          </SiteLink>
        </div>

        <button
          id='mobile-menu-icon'
          type='button'
          aria-label='Menu'
          className={`hamburger hamburger--collapse ${
            menuOpened ? "is-active" : ""
          }`}
          onClick={toggleMenu}
        >
          <span className='hamburger-box'>
            <span className='hamburger-inner'></span>
          </span>
        </button>

        {/* <LandingTopNav /> */}

        <NavBar
          open={menuOpened}
          onCloseMenu={onCloseMenu}
          currentLocation={currentLocation}
        ></NavBar>
      </header>
    </>
  );
}
