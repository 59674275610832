const overloading = {
  breakpointNames: ['sm', 'md', 'lg', 'xl', 'xxl'],
  breakpoints: [576, 768, 992, 1200, 1600]
}

const variables = {
  familyBase: '"Roboto",sans-serif',
  familyHeader: '"Bayon",serif',
  familyHeader2: '"Permanent Marker",serif',

  primaryColor: '#faa222',
  fontColor: '#404040',
  bgColor: '#ebebeb',

  ...overloading
}

export default variables;