/** @jsx jsx */
import { jsx } from '@emotion/core'
import { topNav } from './LandingTopNavStyle';
import { SiteLink } from '../../components';

export function LandingTopNav({style})
{
  return <section css={[topNav.nav, style && style.nav]} className="landingTopNav" id="landingTopNavCms">
  <div css={topNav.skew}>
    <div css={topNav.unskew}>
      <SiteLink css={[topNav.link, style && style.link]} to="#buynow">Buy Now</SiteLink>
      {/* <SiteLink css={topNav.link} to="/contact">Contact Us</SiteLink> */}
    </div>
  </div>
  </section>
}