import React from 'react';
import cmsUtils from '../../utils/cmsUtils';
import utils from '../../../utils';
import { SiteLink } from '../../../components';

export function LinkTileItem(props) {
  const item = props.item;
  const cmsOption = props.cmsOption || {};
  const index = props.index;
  const bgUrl = utils.site.resourcePath(cmsUtils.payload(item, 'ImageUrl'));
  //const bgUrl = "https://conairman.stg5.jaba.com.au/sites/conairman/media/miscellaneous/conairman/model-tile-24.jpg";

  const captionTitle = cmsUtils.payload(item, 'CaptionTitle');
  const captionButton = cmsUtils.payload(item, 'CaptionButton');
  const linkUrl = cmsUtils.payload(item, 'LinkUrl');

  //console.log('item', linkUrl);
  const titlePanel = captionTitle ? (
    linkUrl ? (
    <div>
      <div className="linkTile__title" dangerouslySetInnerHTML={{ __html: captionTitle }}></div>
      
      {
        (captionButton && captionButton !== '') &&
        <SiteLink to={linkUrl} className="linkTile__link"><span>{captionButton}</span></SiteLink>
      }

    </div>
    ) : (<div className="linkTile__title" dangerouslySetInnerHTML={{ __html: captionTitle }}></div>)
  ) : null;

  const cssClass = utils.classNames('cms_item', 'linkTile', `index__${index}`,  `linkTile--${linkUrl ? 'link' : ''}`, item.cssClass || item.anchorName || '', titlePanel && 'bannerHasTitlePanel');

  return (
    <div className={cssClass} data-cms-item-id={cmsOption.isCmsEdit && item.itemId} id={item.anchorName}>
      {linkUrl ?
        <SiteLink to={linkUrl} className="linkTile__tileLink">
          <img className="linkTile__bg" src={bgUrl} alt={captionTitle} />
          {titlePanel && <div className="linkTile__text">{titlePanel}</div>}
        </SiteLink> :
        <React.Fragment>
          <img className="linkTile__bg" src={bgUrl} alt={captionTitle} />
          {titlePanel && <div className="linkTile__text">{titlePanel}</div>}
        </React.Fragment>}
    </div>
  );


}