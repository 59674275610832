/** @jsx jsx */
import { jsx } from '@emotion/core';
import { useLayoutEffect } from 'react';

export function InstagramFeedEmbed(props){
    
    useLayoutEffect(() => {
        const existingScript = document.getElementById('EmbedSocialHashtagScript');
        if(existingScript){
            document.body.removeChild(existingScript);
        }
        const script = document.createElement('script');
        script.src = 'https://embedsocial.com/cdn/ht.js';
        script.id = 'EmbedSocialHashtagScript';
        document.body.appendChild(script);

        // eslint-disable-next-line
    }, []);

    return(
        <div class="embedsocial-hashtag" data-ref="3885b275825a9e1c88a8a1da4242e2271c367ecb"></div>
    )

}