import React from 'react';
import { Item } from '../../items/item';
import cmsUtils from '../../utils/cmsUtils';
import utils from '../../../utils';
import './style.scss';
import Slider from "react-slick";

export function LinkTiles(props) {
  const item = props.item;
  const subItems = item.items || [];
  const maxItemsNumber = subItems.length;
  const cmsOption = props.cmsOption || {};
  const useSlider = cmsUtils.payload(item, 'UseSlider') || "no";
  const sliderContainer = React.useRef(null);
  const tiles = subItems.map((subItem, index) =>
    <Item item={subItem} key={subItem.itemId} cmsOption={cmsOption} index={index}></Item>
  );

  const cssClass = utils.classNames('cms_item', 'linkTiles', 'use-slider-' + item.itemId, (useSlider && useSlider === 'yes' ? 'use__slider' : ''), item.cssClass || item.anchorName || '');
  var numOfSlides = (maxNum) => subItems.length >= maxNum ? maxNum : subItems.length;

  var settings = {
    dots: false,
    infinite: true,
    autoplay: true,
    speed: 1500,
    autoplaySpeed: 3000,
    slidesToShow: numOfSlides(5),
    slidesToScroll: numOfSlides(1),
    //cssEase: "linear",
    className: 'center',
    centerMode: true,
    centerPadding: '60px',
    onInit: () => {

      let _willBecomSmallBigImgs1 = document.querySelectorAll(`.use__slider.${'use-slider-' + item.itemId} .slick-slide:has(.linkTile.index__1)`);
      let _willBecomSmallBigImgs2 = document.querySelectorAll(`.use__slider.${'use-slider-' + item.itemId} .slick-slide:has(.linkTile.index__${maxItemsNumber - 1})`);
      
      let _willBecomSmallBigImgs3 = document.querySelectorAll(`.use__slider.${'use-slider-' + item.itemId} .slick-slide:has(.linkTile.index__2)`);
      let _willBecomSmallBigImgs4 = document.querySelectorAll(`.use__slider.${'use-slider-' + item.itemId} .slick-slide:has(.linkTile.index__${maxItemsNumber - 2})`);

      _willBecomSmallBigImgs1.forEach(el => {
        el.style.transform = `scale(${window.innerWidth >= 576 ? 1.15 : 0.95 })`;
        el.style.zIndex = 3;
      });

      _willBecomSmallBigImgs2.forEach(el => {
        el.style.transform = `scale(${window.innerWidth >= 576 ? 1.15 : 0.95 })`;
        el.style.zIndex = 3;
      });

      _willBecomSmallBigImgs3.forEach(el => {
        el.style.transform = `scale(1.1)`;
        el.style.zIndex = 2;
      });

      _willBecomSmallBigImgs4.forEach(el => {
        el.style.transform = `scale(1.1)`;
        el.style.zIndex = 2;
      });

    },

    beforeChange: (current, next) => {

      let _allslickSlide = sliderContainer.current.querySelectorAll(`.slick-slide`);
      _allslickSlide.forEach(el => {
        el.style.zIndex = 'auto';
        el.style.transform = 'scale(1)';
        //el.style.opacity = 1;
      });

      let _indexNumb1 = next + 1 > (maxItemsNumber - 1) ? 0 : next + 1;
      let _indexNumb2 = next - 1 < 0 ? 5 : next - 1;

      let _indexNumb3 = next + 2 >= 6 ? (next + 2 - 6) : next + 2;
      let _indexNumb4 = next - 2 <= -1 ? (next - 2 + 6) : next - 2;

      let _willBecomSmallBigImgs1 = sliderContainer.current.querySelectorAll(`.slick-slide:has(.linkTile.index__${_indexNumb1})`);
      let _willBecomSmallBigImgs2 = sliderContainer.current.querySelectorAll(`.slick-slide:has(.linkTile.index__${_indexNumb2})`);

      let _willBecomSmallBigImgs3 = sliderContainer.current.querySelectorAll(`.slick-slide:has(.linkTile.index__${_indexNumb3})`);
      let _willBecomSmallBigImgs4 = sliderContainer.current.querySelectorAll(`.slick-slide:has(.linkTile.index__${_indexNumb4})`);

      _willBecomSmallBigImgs1.forEach(el => {
        el.style.transform = `scale(${window.innerWidth >= 576 ? 1.15 : 0.95 })`;
        el.style.zIndex = 3;
      });

      _willBecomSmallBigImgs2.forEach(el => {
        el.style.transform = `scale(${window.innerWidth >= 576 ? 1.15 : 0.95 })`;
        el.style.zIndex = 3;
      });

      _willBecomSmallBigImgs3.forEach(el => {
        el.style.transform = 'scale(1.1)';
        el.style.zIndex = 2;
      });

      _willBecomSmallBigImgs4.forEach(el => {
        el.style.transform = 'scale(1.1)';
        el.style.zIndex = 2;
      });

    },

    responsive: [
      { breakpoint: 1024, settings: { slidesToShow: numOfSlides(5) } },
      { breakpoint: 992, settings: { slidesToShow: numOfSlides(3) } },
      { breakpoint: 768, settings: { slidesToShow: numOfSlides(1) } },
      { breakpoint: 576, settings: { slidesToShow: numOfSlides(1) } }
    ]
  };



  return (
    <div ref={sliderContainer} className={cssClass} data-cms-item-id={cmsOption.isCmsEdit && item.itemId} id={item.anchorName}>
      {
        useSlider && useSlider === 'yes' ?
          <Slider {...settings}>
            {tiles}
          </Slider> : tiles
      }
    </div>
  );
}