import utils from "../utils";
import env from '../env';

export const isMobile = () => {
  var width = utils.css.screenWidth()
  return width < 768
}

export function resourcePath(url){
  return utils.url.isExternalLink(url)? url : env.resourceBase + url;
}

export function fullUrl(url){
  return utils.url.isExternalLink(url)? url : env.baseOrigin + url;
}

export function getAlternates(path){
  const alternates = [];

  alternates.push({hreflang: "en-au", href: "https://www.conairman.com.au" + path})
  alternates.push({hreflang: "en-nz", href: "https://www.www.conairman.co.nz" + path})
  alternates.push({hreflang: "x-default", href: "https://www.conairman.com.au" + path})

  return alternates;
}

export const isNZ = env.locale === 'nz'