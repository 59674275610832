/** @jsx jsx */
import { jsx } from "@emotion/core";
import style from "./productViewStyle";
import React, { Fragment } from "react";
import env from "../../env";
import { SiteLink, NotFound } from "../../components";
import { useGet } from "../../components";
import { useRouter } from "../../components";
import utils from "../../utils";
import { ErrorPopup, Loading } from "../../components";
import { Breadcrumb } from "../../components/breadcrumb/breadcrumb";
import { ViewPartial } from "../../cms/pages/partials/viewPartial";
import { CommonProductBottom } from "../../cms/pages/partials/commonProductBottom";
import { Tile } from "./productTile";
import { YoutubeEmbed } from "../../components";
import { useInstagram } from "../../components";
import { useBV } from "../bazzarVoice/useBV";
//import { ImagePopup } from 'modules/imagePopup/imagePopup';
// import InstagramEmbed from "react-instagram-embed";
import Slider from "react-slick";
import FsLightbox from "fslightbox-react";
import { MetaTag } from "../../components";
import { FaRegFilePdf } from "react-icons/fa";
import { IoMdOpen } from "react-icons/io";

import { debounce } from "../../lib/utils-core/debounce";
import { gtm } from "../../lib/tracking";
import { InstaEmbedByLink } from "../embed/instagram";

export function ProductView(props) {
  const { query, Redirect } = useRouter();
  const [loaded, setLoaded] = React.useState(false);
  const get = useGet();
  useInstagram();

  const productCode = query.id || "";
  const category = query.category || "";
  const slug = query.slug || "";
  //console.log(category, slug, productCode)

  React.useEffect(() => {
    const url = productCode
      ? `/api/product/getProduct/${productCode}`
      : `/api/product/getProductByUrl?category=${category}&slug=${slug}`;
    get.send(env.apiBase + url);
    setLoaded(false);
    // eslint-disable-next-line
  }, [productCode, slug]);
  
  if (get.loading()) {
    return <Loading />;
  } else if (get.hasErrors() && get.containsErrorMessage("no product")) {
    return <NotFound />;
  } else if (get.hasErrors()) {
    return <ErrorPopup title='Loading Error' errors={get.errors} />;
  } else if (get.response && get.response.redirectTo && get.response.redirectTo !== '') {
    return <Redirect to={get.response.redirectTo} />;
  }

  //console.log(get)
  const data = get.response;

  if (get.done() && !loaded) {
    setLoaded(true);
    const pageUrl = productCode
      ? `/product/${productCode}`
      : `/products/${category}/${slug}`;
    const pageTitle =
      data.totalCnt === 0 ? "No product found" : data.productName;
    gtm.pageView(pageUrl, pageTitle);
  }

  if (data.totalCnt === 0) return <div>No product found.</div>;
  return (
    <React.Fragment>
      <div
        className='single-banner banner-item bannerSize--thin'
        css={style.singBanner}
        style={{
          backgroundImage: utils.css.bgUrlStyle("/assets/hero-group-01.jpg"),
        }}
      ></div>
      <Product product={data} />

      <CommonProductBottom />
      <ViewPartial />
    </React.Fragment>
  );
}

function Product({ product }) {
  const mainPhoto =
    product.productImages.length > 0
      ? product.productImages[0]
      : { imageUrl: "/assets/product-empty.png", alt: product.productName };
  const allPhotos = product.productImages || [];
  //const otherPhotos = product.productImages.length > 0 ? product.productImages.filter(x => !x.isPackage) : [];
  const packagePhotos =
    product.productImages.length > 0
      ? product.productImages.filter((x) => x.isPackage)
      : [];

  const popupImages = allPhotos.map((x) => x.imageUrl);

  const [isMobile, setIsMobile] = React.useState(utils.site.isMobile());

  const [currentTab, setCurrentTab] = React.useState(
    isMobile ? "wheretobuy" : "wheretobuy"
  );
  const [heroImage, setHeroImage] = React.useState(mainPhoto);

  const [toggler, setToggler] = React.useState(false);
  //const [popupImages, setPopupImages] = React.useState();
  const [popupIndex, setPopupIndex] = React.useState(0);
  const country = utils.site.isNZ ? "New Zealand" : "Australia";
  const schemaImages = product.productImages.map(
    (x) =>
      (country === "New Zealand"
        ? "https://www.conairman.com.au"
        : "https://www.conairman.co.nz") + x.imageUrl
  );

  React.useEffect(() => {
    const resized = debounce(() => {
      setIsMobile(utils.site.isMobile());
    }, 1000);
    if (typeof window !== `undefined`) {
      window.addEventListener("resize", resized);
    }

    const settingSchemaInterval = setInterval(() => {
      const bvRatingSchema = document.getElementById(
        "bv-jsonld-bvloader-summary"
      );

      if (bvRatingSchema) {
        const bvRatingSchemaData = JSON.parse(bvRatingSchema.innerHTML);
        const productStructuredData = {
          "@context": "https://schema.org/",
          "@type": "Product",
          name: product.productName,
          url:
            (country === "New Zealand"
              ? "https://www.conairman.co.nz"
              : "https://www.conairman.com.au") + product.productUrl,
          description: product.intro,
          sku: product.productCode,
          brand: {
            "@type": "Brand",
            name: "ConairMan",
          },
          gtin13:
            product.barcode && product.barcode !== "" ? product.barcode : "",
          category: product.category,
          image: schemaImages,
          aggregateRating: bvRatingSchemaData.aggregateRating,
          offers: {
            "@type": "Offer",
            url:
              (country === "New Zealand"
                ? "https://www.conairman.co.nz"
                : "https://www.conairman.com.au") + product.productUrl,
            priceCurrency: country === "New Zealand" ? "NZD" : "AUD",
            price: product.price + "",
            availability: "https://schema.org/InStock",
            itemCondition: "https://schema.org/NewCondition",
          },
        };

        const productSchemaScript = document.createElement("script");
        productSchemaScript.id = "productSchemaScript";
        productSchemaScript.type = "application/ld+json";
        productSchemaScript.innerHTML = JSON.stringify(productStructuredData);

        document.head.removeChild(bvRatingSchema);
        document.body.appendChild(productSchemaScript);
        clearInterval(settingSchemaInterval);
      } else {
        const productStructuredData = {
          "@context": "https://schema.org/",
          "@type": "Product",
          name: product.productName,
          url:
            (country === "New Zealand"
              ? "https://www.conairman.co.nz"
              : "https://www.conairman.com.au") + product.productUrl,
          description: product.intro,
          sku: product.productCode,
          brand: {
            "@type": "Brand",
            name: "ConairMan",
          },
          gtin13:
            product.barcode && product.barcode !== "" ? product.barcode : "",
          category: product.category,
          image: schemaImages,
          offers: {
            "@type": "Offer",
            url:
              (country === "New Zealand"
                ? "https://www.conairman.co.nz"
                : "https://www.conairman.com.au") + product.productUrl,
            priceCurrency: country === "New Zealand" ? "NZD" : "AUD",
            price: product.price + "",
            availability: "https://schema.org/InStock",
            itemCondition: "https://schema.org/NewCondition",
          },
        };

        const productSchemaScript = document.createElement("script");
        productSchemaScript.id = "productSchemaScript";
        productSchemaScript.type = "application/ld+json";
        productSchemaScript.innerHTML = JSON.stringify(productStructuredData);

        document.body.appendChild(productSchemaScript);
        clearInterval(settingSchemaInterval);
      }
    }, 100);

    const checkBvReviewSchemaSI = setInterval(() => {
      const bvReviewSchemaScript = document.getElementById(
        "bv-jsonld-reviews-data"
      );
      if (bvReviewSchemaScript) {
        document.head.removeChild(bvReviewSchemaScript);
        clearInterval(checkBvReviewSchemaSI);
      }
    }, 100);

    return () => {
      if (typeof window !== `undefined`) {
        window.removeEventListener("resize", resized);
      }
      clearInterval(settingSchemaInterval);
      clearInterval(checkBvReviewSchemaSI);
    };

    // eslint-disable-next-line
  }, []);

  React.useLayoutEffect(() => {
    setTimeout(() => {
      bv.sendEvent(
        "WhereToBuy",
        "WhereToBuyTab_ProductPage",
        product.productCode
      );
    }, 1500);

    // eslint-disable-next-line
  }, []);

  // BV
  const onShowReview = React.useCallback((e) => {
    //e.stopPropagation();
    showTab("reviews", true);
    // eslint-disable-next-line
  }, []);

  const bv = useBV(product, {
    showReview: onShowReview,
  });

  function openPopupImage(imageUrl) {
    const inx = utils.array.findIndex(popupImages, (x) => x === imageUrl);
    setPopupIndex(inx >= 0 ? inx : 0);
    setToggler(!toggler);
  }

  function showTab(tab, forceToScroll = false) {
    if (tab === currentTab && isMobile) {
      setCurrentTab("");
      return;
    }
    if (tab === "wheretobuy") {
      //bv.sendEvent("WhereToBuy", "WhereToBuyTab_ProductPage", product.productCode);
    }

    if (tab !== currentTab) {
      gtm.sendGAEvent("Product Tab", "Select", tab);
    }
    setCurrentTab(tab);
    scrollTo("tab-" + tab, forceToScroll);
  }

  function scrollTo(selector, forceToScroll = false) {
    if (!forceToScroll && !isMobile) return;
    setTimeout(() => {
      const anchor = utils.ui.findElement(selector);
      if (anchor) utils.ui.scrollTo(anchor, 300);
    }, 200);
  }

  const slides = allPhotos.map((photo, index) => (
    <div
      key={index}
      className={`productView__image ${
        heroImage.imageUrl === photo.imageUrl ? "current" : ""
      }`}
    >
      <div
        className={`productView__imageBg`}
        style={{
          backgroundImage: utils.css.bgUrlStyle(
            utils.site.resourcePath(photo.imageUrl)
          ),
        }}
        onClick={() => setHeroImage(photo)}
      ></div>
      {/* <img src={utils.site.resourcePath(photo.imageUrl)} alt={photo.alt} onClick={() => setHeroImage(photo)} /> */}
    </div>
  ));

  var settings = {
    dots: false,
    infinite: slides.length > 2 ? true : false,
    speed: 500,
    autoplaySpeed: 3000,
    initialSlide: 1,
    //fade: true,
    autoplay: false,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const tabs = [
    {
      id: "wheretobuy",
      title: "Where to buy",
      tabView: WhereToBuyTab,
      visible:
        (product.whereToBuySection || []).length > 0 ||
        (product.whereToBuys || []).length > 0,
    },
    { id: "reviews", title: "Reviews", tabView: BVReviewTab, visible: true },
    { id: "details", title: "Details", tabView: DetailsTab, visible: true },
    {
      id: "howto",
      title: "How To",
      tabView: HowtoTab,
      visible: (product.HowtoTab || []).length > 0,
    },
    {
      id: "seemore",
      title: "Pro Tips",
      tabView: SeeMoreTab,
      visible: (product.seeMoreSection || []).length > 0,
    },
    {
      id: "faqs",
      title: "FAQs",
      tabView: FaqTab,
      visible: (product.faqSection || []).length > 0,
    },
  ];
  const visibleTabs = tabs.filter((tab) => tab.visible);
  const tabPanels = isMobile ? (
    <div className='productView_tabPanels--mobile'>
      {visibleTabs.map((tab) => (
        <div key={tab.id} className='productView_section'>
          <div
            id={tab.id}
            className={`tab ${(currentTab === tab.id && "selected") || ""}`}
            onClick={(e) => showTab(tab.id)}
          >
            {tab.title}
          </div>
          <div
            className={`tabContent ${
              (currentTab === tab.id && "selected") || ""
            }`}
          >
            <tab.tabView product={product} />
          </div>
        </div>
      ))}
    </div>
  ) : (
    <div className='productView_tabPanels--desktop'>
      <div className='productView__tabs'>
        {visibleTabs.map((tab) => (
          <div
            key={tab.id}
            id={tab.id}
            className={`tab ${(currentTab === tab.id && "selected") || ""}`}
            onClick={(e) => showTab(tab.id)}
          >
            {tab.title}
          </div>
        ))}
      </div>

      <div className='productView__tabContents'>
        {visibleTabs.map((tab) => (
          <div
            key={tab.id}
            className={`tabContent ${
              (currentTab === tab.id && "selected") || ""
            }`}
          >
            <tab.tabView product={product} />
          </div>
        ))}
      </div>
    </div>
  );

  const discoverMoreLinks = product.discoverMoreSection.map((x) =>
    JSON.parse(x.itemValue)
  );
  const barcodeCleaned = product.barcode
    ? product.barcode.replace(/-/g, "").replace(/ /g, "")
    : null;

  return (
    <div className='contentPanel productViewPanel'>
      <FsLightbox
        toggler={toggler}
        sources={popupImages}
        sourceIndex={popupIndex}
      />
      <MetaTag data={getMetaTag(product, mainPhoto)} />

      <div className='contentPanel__bg'></div>
      <div className='container'>
        <Breadcrumb breadcrumb={product.breadcrumb} />
        <div className='productView'>
          <div itemScope={true} itemType='http://schema.org/Product'>
            <meta itemProp='name' content={product.productName} />
            <div itemProp='brand' itemType='http://schema.org/Brand' itemScope>
              <meta itemProp='name' content={env.siteName} />
            </div>

            {product.intro && (
              <meta itemProp='description' content={product.intro} />
            )}
            {barcodeCleaned && <meta itemProp='sku' content={barcodeCleaned} />}
            {barcodeCleaned && (
              <meta itemProp='gtin13' content={barcodeCleaned} />
            )}
            {heroImage && heroImage.imageUrl && (
              <link
                itemProp='image'
                href={utils.site.fullUrl(heroImage.imageUrl)}
              />
            )}

            <div itemProp='offers' itemType='http://schema.org/Offer' itemScope>
              <link
                itemProp='url'
                href={utils.site.resourcePath(product.productUrl)}
              />
              {product.price && (
                <meta itemProp='price' content={product.price} />
              )}
              <meta
                itemProp='priceCurrency'
                content={utils.site.isNZ ? "NZD" : "AUD"}
              />
            </div>

            <div className='row'>
              <div className='col-md-6'>
                {/* <ImagePopup
            src={utils.site.resourcePath(heroImage.imageUrl)} 
            thumbnailSrc={utils.site.resourcePath(heroImage.imageUrl)}
            alt={heroImage.alt} title={heroImage.alt} /> */}
                <img
                  className='productView__hero'
                  src={utils.site.resourcePath(heroImage.imageUrl)}
                  alt={heroImage.alt || product.productName}
                  onClick={() => openPopupImage(heroImage.imageUrl)}
                />
                {allPhotos.length > 1 && (
                  <div className='productView__images'>
                    <Slider {...settings}>{slides}</Slider>
                  </div>
                )}
              </div>
              <div className='col-md-6 right'>
                {product.productName.indexOf("i-") === 0 ? (
                  <h1 className='productView__title'>
                    <strong style={{ fontWeight: "500" }}>
                      {product.productName.toUpperCase().replace("I-", "i-")}
                    </strong>
                  </h1>
                ) : (
                  <h1
                    className='productView__title'
                    style={{ textTransform: "uppercase" }}
                  >
                    <strong style={{ fontWeight: "500" }}>
                      {product.productName}
                    </strong>
                  </h1>
                )}
                <div className='productView__price'>
                  {product.priceText ? product.priceText : ""}
                </div>
                <div className='productView__productCode'>
                  Product code: <b>{product.productCode}</b>
                </div>
                <div className='productView__bzSummary'>
                  <div
                    data-bv-show='rating_summary'
                    data-bv-product-id={product.productCode}
                  ></div>
                </div>
                {product.productStatus === 98 /*Discontinued*/ && (
                  <div className='productView__discontinued'>
                    This product is now discontinued.
                  </div>
                )}
                <div className='productView__intro'>{product.intro}</div>
                {discoverMoreLinks && (
                  <div className='productView__discoverMores'>
                    {discoverMoreLinks.map((link, index) => (
                      <div key={index} style={{ marginBottom: "0.8rem" }}>
                        <SiteLink
                          className='productView__skewButton'
                          to={link.url}
                        >
                          <span>{link.title}</span>
                        </SiteLink>
                      </div>
                    ))}
                  </div>
                )}

                <div className='productView__packages'>
                  {packagePhotos.map((photo, index) => (
                    <div key={index} className='productView__package'>
                      <img
                        src={utils.site.resourcePath(photo.imageUrl)}
                        alt={photo.alt || product.productName + " Packages"}
                        onClick={() => openPopupImage(photo.imageUrl)}
                      />
                    </div>
                  ))}
                </div>
              </div>
            </div>

            {tabPanels}

            <RelatedProducts products={product.relatedProducts} />
          </div>
        </div>
      </div>
    </div>
  );
}

function getMetaTag(product, mainPhoto) {
  const country = utils.site.isNZ ? "New Zealand" : "Australia";
  const siteName = env.siteName + " " + country;

  return {
    title: product.productName + " | " + siteName,
    description: product.metaDesc || product.intro,
    keywords: "",
    heroImageUrl: utils.site.fullUrl(mainPhoto.imageUrl),
    language: "English",
    country: country,
    siteName: siteName,
    url: utils.site.fullUrl(product.productUrl),
    alternates: utils.site.getAlternates(product.productUrl),

    //facebook-domain-verification
    faceBookDomainVerifi:
      country === "New Zealand"
        ? env.FaceBookDomainVerifiNZ
        : env.FaceBookDomainVerifiAU,
    //canonical: utils.site.resourcePath(pageData.pageUrl)
  };
}

function BVReviewTab({ product }) {
  return (
    <div data-bv-show='reviews' data-bv-product-id={product.productCode}></div>
  );
}

function WhereToBuyTab({ product }) {
  const section = product.whereToBuySection || [];
  return (
    <div>
      {section.length > 0 && <ItemList product={product} section={section} />}
      {product.whereToBuys && product.whereToBuys.length > 0 && (
        <Stores stores={product.whereToBuys} />
      )}
    </div>
  );
}

function RelatedProducts({ products }) {
  //console.log(products)
  if (products.length === 0) return null;
  return (
    <div className='productView__relatedProducts'>
      <h3>RELATED PRODUCTS</h3>
      <div className='productList'>
        {products.map((product, index) => (
          <Tile product={product} key={product.productCode}></Tile>
        ))}
      </div>
    </div>
  );
}

function Stores({ stores }) {
  return (
    <ul className='stores'>
      {stores.map((store) => (
        <li key={store.storeCode} className='store'>
          <figure>
          {store.storeUrl && store.storeUrl !== '' && store.buyOnline ? (
            <a
              href={store.storeUrl}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={`/assets/shops/${store.iconPath}`}
                alt={store.storeName}
              />
              <figcaption>Buy Online</figcaption>
              {/*<figcaption>{store.storeName}</figcaption>*/}
            </a>
          ) : (
            <Fragment>
              <img
                src={`/assets/shops/${store.iconPath}`}
                alt={store.storeName}
              />
              {/*<figcaption>{store.storeName}</figcaption>*/}
            </Fragment>
          )}
          {/*<img
              src={`/assets/shops/${store.iconPath}`}
              alt={store.storeName}
            />
            <figcaption>{store.storeName}</figcaption>
            {store.buyOnline && (
              <a
                href={store.storeUrl}
                target='_blank'
                rel='noopener noreferrer'
              >
                Buy online <span className='icon'></span>
              </a>
            )}*/}
          </figure>
        </li>
      ))}
    </ul>
  );
}

function HowtoTab({ product }) {
  const section = product.howToSection || [];
  return <ItemList product={product} section={section} />;
}

function FaqTab({ product }) {
  const section = product.faqSection || [];
  return <ItemList product={product} section={section} />;
}

function DetailsTab({ product }) {
  const section = product.detailSection || [];
  const hasWarranty = section.some(
    (x) => (x.itemValue || "").indexOf("WARRANTY") >= 0
  );

  const techSpecs =
    product.specSection && product.specSection.length > 0
      ? TechSpecs(product.specSection)
      : null;
  const hasTech = section.some(
    (x) => (x.itemValue || "").indexOf("TECHNICAL SPECIFICATIONS") >= 0
  );

  return (
    <React.Fragment>
      <ItemList product={product} section={section} />
      {!hasTech && techSpecs && (
        <div>
          <h3>TECH TALK</h3>
          {techSpecs}
        </div>
      )}
      {!hasWarranty && product.warranty && (
        <Warranty
          warrantyYear={product.warranty}
          warrantyDetails={product.warrantyDetails}
        />
      )}
      {product.barcode && (
        <div>
          <h3>BARCODE</h3>
          <p>{product.barcode}</p>
        </div>
      )}
      {product.useSparePartInstruction && <SparePart />}
      {product.bookletLink && <Booklet bookletLink={product.bookletLink} />}
    </React.Fragment>
  );
}

function SparePart() {
  return (
    <div>
      <h3>SPARE PARTS</h3>
      <p>
        Lost a part to your favourite ConairMan tool? No problem, contact our{" "}
        <SiteLink to='/contact'>Customer Service team</SiteLink> & they’ll sort
        you out.
      </p>
    </div>
  );
}

function Warranty({ warrantyYear, warrantyDetails }) {
  if (warrantyDetails) {
    return (
      <div>
        <h3>WARRANTY</h3>
        <p>Backed by a {warrantyYear} year warranty*</p>
        <p className='warranty-details'>* {warrantyDetails}</p>
      </div>
    );
  } else {
    return (
      <div>
        <h3>WARRANTY</h3>
        <p>Backed by a {warrantyYear} year warranty</p>
      </div>
    );
  }
}

function Booklet({ bookletLink }) {
  //const bookletTitle = utils.url.getFilename(bookletLink);
  return (
    <div>
      <h3>INSTRUCTION BOOKLET</h3>
      <a href={bookletLink} target='_blank' rel='noopener noreferrer'>
        <FaRegFilePdf /> Download Instruction Booklet
      </a>
    </div>
  );
}

function TechSpecs(items) {
  const specs = items.map((x) => JSON.parse(x.itemValue));
  const left = specs.filter((x) => x.category !== "R");
  const right = specs.filter((x) => x.category === "R");
  if (right.length === 0) {
    // render all - men
    const splitLefts = [],
      splitRights = [];
    left.forEach((x, index) =>
      index % 2 === 0 ? splitLefts.push(x) : splitRights.push(x)
    );
    return (
      <div className='row' style={{ marginBottom: "0.5rem" }}>
        <div className='col-md-6'>
          {splitLefts.map((x, index) => (
            <div key={index}>
              <b>{x.label}</b>: {x.text}
            </div>
          ))}
        </div>
        <div className='col-md-6'>
          {splitRights.map((x, index) => (
            <div key={index}>
              <b>{x.label}</b>: {x.text}
            </div>
          ))}
        </div>
      </div>
    );
  } else {
    // render left/right - women
    return (
      <div className='row' style={{ marginBottom: "0.5rem" }}>
        <div className='col-md-6'>
          {left.map((x, index) => (
            <div key={index}>
              <b>{x.label}</b>: {x.text}
            </div>
          ))}
        </div>
        <div className='col-md-6'>
          {right.map((x, index) => (
            <div key={index}>
              <b>{x.label}</b>: {x.text}
            </div>
          ))}
        </div>
      </div>
    );
  }
}

function SeeMoreTab({ product }) {
  const section = product.seeMoreSection || [];
  const blogs = [],
    instagrams = [];
  const pre = [],
    post = [];
  const empty = [0, 1, 2, 3, 4];

  let hasInstaOrBlog = false;
  section.forEach((item) => {
    //console.log(item)
    let processed = false;
    if (item.itemTypeCode === "link") {
      const link = JSON.parse(item.itemValue);
      if (link.url && link.url.indexOf("instagram.com") >= 0) {
        hasInstaOrBlog = true;
        processed = true;
        instagrams.push(item);
      } else if (link.linkType === "blog" || link.linkType === "page") {
        hasInstaOrBlog = true;
        processed = true;
        blogs.push(item);
      }
    }
    if (!processed) {
      if (hasInstaOrBlog) {
        post.push(item);
      } else {
        pre.push(item);
      }
    }
  });

  //console.log(pre, post)
  return (
    <React.Fragment>
      <div>
        <ItemList product={product} section={pre} />
      </div>
      <div className='blogTiles tiles blogTiles--productView'>
        <ItemList product={product} section={blogs} />
      </div>
      <div css={style.instagrams}>
        <ItemList product={product} section={instagrams} />
        {empty.map((x) => (
          <Empty key={x} />
        ))}
      </div>
      <div>
        <ItemList product={product} section={post} />
      </div>
    </React.Fragment>
  );
}

// function ReviewTab({ product }) {
//   return <div>Coming...</div>
// }

function ItemList({ section }) {
  //console.log(section)
  return (
    <React.Fragment>
      {section.map((item, index) => (
        <ItemView item={item} key={index} />
      ))}
    </React.Fragment>
  );
}

function ItemView({ item }) {
  //console.log(item, item.itemTypeCode, item.itemTypeCode === 'html')
  if (item.itemTypeCode === "html") {
    return <div dangerouslySetInnerHTML={{ __html: item.itemValue }}></div>;
  }
  if (item.itemTypeCode === "video") {
    const video = JSON.parse(item.itemValue);

    if (video.video) {
      return (
        <div className='row video'>
          <div className='col-md-5'>
            <YoutubeEmbed url={video.video} title={video.title} />
          </div>
          <div className='col-md-7'>
            <h4>{video.title}</h4>
            <div dangerouslySetInnerHTML={{ __html: video.desc }}></div>
          </div>
        </div>
      );
    } else {
      return (
        <div className='row video'>
          <div className='col-12'>
            <h3>{video.title}</h3>
            <div dangerouslySetInnerHTML={{ __html: video.desc }}></div>
          </div>
        </div>
      );
    }
  }
  if (item.itemTypeCode === "link") {
    let link = JSON.parse(item.itemValue);
    let title = link.title;
    if (!link.title) {
      title = (link.url || "").split("/").pop();
    }
    // console.log(link, env.instagramUseFirst);
    if (
      link.linkType === "instagram" ||
      (link.url && link.url.indexOf("instagram.com") >= 0)
    ) {
      return (
        <div className='productView__linkItem productView__linkItem--instagram'>
          {env.instagramUseFirst === "embed" && link.instagramEmbed ? (
            <div
              dangerouslySetInnerHTML={{ __html: link.instagramEmbed }}
            ></div>
          ) : link.url ? (
            // <InstagramEmbed
            //   url={link.url}
            //   maxWidth={320}
            //   hideCaption={true}
            //   clientAccessToken={env.instagramClientAccessToken}
            // />
            <InstaEmbedByLink url={link.url} />
          ) : null}
        </div>
      );
    }
    if (link.linkType === "blog" || link.linkType === "page") {
      return <PageTile page={link} />;
    }
    if (link.url && link.url.indexOf("http") < 0) {
      return (
        <div>
          <SiteLink
            className='productView__linkItem productView__linkItem--page'
            to={link.url}
          >
            {title}
          </SiteLink>
        </div>
      );
    }
    if (link.url && link.url.indexOf("http") >= 0 && link.imageUrl) {
      return (
        <PageTile
          page={{
            imageUrl: link.imageUrl,
            url: link.url,
            title: title,
            tileDesc: "", //link.desc
          }}
        />
      );
    }
    return (
      <div>
        <a
          className='productView__linkItem'
          href={link.url}
          target='_blank'
          rel='noopener noreferrer'
        >
          <IoMdOpen /> {title}
        </a>
      </div>
    );
  }
  if (item.itemTypeCode === "qa") {
    const qa = JSON.parse(item.itemValue);
    return (
      <div css={style.qa}>
        <div css={style.qaQuestion}>{qa.question}</div>
        <div
          css={style.qaAnswer}
          dangerouslySetInnerHTML={{ __html: qa.answer }}
        ></div>
      </div>
    );
  }
  if (item.itemTypeCode === "spec") {
    const spec = JSON.parse(item.itemValue);

    return (
      <div>
        <strong>{spec.label}</strong>: {spec.text}
      </div>
    );
  }
  return <div></div>;
}

function Empty() {
  return (
    <div className='productView__empty'>
      <div></div>
    </div>
  );
}

function PageTile({ page }) {
  const imageUrl = utils.site.resourcePath(page.imageUrl);
  return (
    <div className='blogTile'>
      <SiteLink className='blogTile__link' to={page.url}>
        <div
          className='blogTile__bg'
          style={{ backgroundImage: utils.css.bgUrlStyle(imageUrl) }}
        ></div>
        <div className='blogTile__content'>
          <div className='blogTile__contentInner'>
            <div className='blogTile__title'>{page.title}</div>
            <div className='blogTile__desc'>{page.tileDesc}</div>
            <div className='blogTile__readMore thinBtn'>READ MORE</div>
          </div>
        </div>
      </SiteLink>
    </div>
  );
}
