import React from 'react';
import env from '../../env';
import { usePost } from '../../components';
import { ErrorPopup, Loading } from '../../components';

import { FilterDropdown } from './filterDropdown';
import * as sortHelper from './sortHelper';
import { Tile } from './productTile';
import { gtm } from '../../lib/tracking';

import { useBV } from '../bazzarVoice/useBV';
import utils from '../../utils';
export function ProductList(props) {

  const post = usePost();
  const [filters, setFilters] = React.useState([]);
  const [sortBy, setSortBy] = React.useState('price-desc');
  const [showAll, setShowAll] = React.useState(false);
  useBV(null, { showReview: null });
  const searchParam = { ...props };

  React.useEffect(() => {
    post.send(env.apiBase + '/api/product/getProducts', searchParam);
    // eslint-disable-next-line
  }, []);

  
  React.useEffect(() => {
    if (post.response && post.response.results && post.response.results.searchResult && filters.length > 0) {
      const data = post.response.results.searchResult;
      const distinctFilters = getDistinctFilters(data.filters);
      const value = filters.map(x => getFilterTitle(distinctFilters, x)).filter(x => x).join(',')
      //console.log(distinctFilters, filters, value)
      gtm.sendGAEvent('Product Filter', 'Filter By', value)
    }
    // eslint-disable-next-line
  }, [filters]);


  if (post.loading()) {
    return <Loading />
  } else if (post.hasErrors()) {
    return <ErrorPopup title="Loading Error" errors={post.errors} />
  }

  //console.log(post)
  const data = post.response.results.searchResult;
  const distinctFilters = getDistinctFilters(data.filters);

  const filterItems = [...distinctFilters];
  const sortItems = sortHelper.sortSelections();

  let filtered = filters.length > 0 ? getFilteredItems(data.items, filters) : data.items

  sortHelper.sort(filtered, sortBy);

  const maxListing = utils.css.screenWidth() > 1600 ? 100 : 120;
  const showMoreButton = !showAll && filters.length > 0 && filtered.length > maxListing;
  //console.log(maxListing, !filter, filtered.length, maxListing,  showMoreButton)
  if (showMoreButton) {
    filtered = filtered.splice(0, maxListing);
  }

  if (data.totalCnt === 0) return <div>No product found.</div>
  return (
    <>
      <div className="prdFilterSort">
        <div className="prdFilter">
          <FilterDropdown title="Filter by*" isMulti={true} items={filterItems} value={filters} onChange={setFilters} clearText="Clear filters" />
        </div>
        <div className="productSort">
          <FilterDropdown items={sortItems} value={sortBy} onChange={setSortBy} rightAligned={true} />
        </div>
      </div>
      <div className="productList">
        {filtered.map((product, index) =>
          <Tile product={product} key={product.productCode}></Tile>
        )}
      </div>
      {showMoreButton && (<div style={{ textAlign: 'center' }}><button className="btn btn-primary" onClick={(e) => {setShowAll(true)}}>Show All</button></div>)}

      <div style={{fontSize:'0.75rem', color: '#777', textAlign: 'left'}}>*Results depend on individual hair type & styling habits. Always read the product instructions before use.</div>
    </>
  );
}

function getFilteredItems(items, filters){
  return items.filter(x => filters.every(filter => x.filterCodes.some(fc => {
    //console.log(filter, fc, filter.indexOf(fc) >= 0)
    return filter.indexOf(fc) >= 0
  })))
  //return items.filter(x => x.filterCodes.some(f => filters.every(t => t.indexOf(f) >= 0)))
}

// get distinct filter names (there could be the same named filter with different id in different categories.)
function getDistinctFilters(filters){
  const distinctFilters = [];
  filters.forEach(x => {
    const found = utils.array.find(distinctFilters, f => f.name === x.filterName);
    if (found){
      found.value = found.value + `${x.filterCode}|`
    } else {
      distinctFilters.push({ label: `${x.filterName}`, value: `|${x.filterCode}|` })
    }
  })
  return distinctFilters;
}

function getFilterTitle(distinctFilters, value){
  const found = utils.array.find(distinctFilters, f => f.value === value);
  return found ? found.label : null;
}